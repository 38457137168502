@import "trix/dist/trix";

.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    display: block;
    text-align: left;

    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }

    .attachment--preview {
      text-align: left;
      width: auto;
      margin-inline-end: unset !important;
      margin-inline-start: unset !important;
    }

    figure {
      margin-inline-end: unset !important;
      margin-inline-start: unset !important;
    }
  }

  figure {
    margin-inline-end: unset !important;
    margin-inline-start: unset !important;
  }

  .attachment--preview {
    text-align: left;
    width: auto;
    margin-inline-end: unset !important;
    margin-inline-start: unset !important;
  }

  // Editor only. Hide the extra caption in the editor.
  .attachment__caption {
    display: none;
  }

  figure {
    margin-inline-end: unset;
    margin-inline-start: unset;
  }

  .attachment--preview {
    text-align: left;
    width: auto;
  }

  // Editor only. Hide the extra caption in the editor.
  .attachment__caption {
    display: none;
  }
}
