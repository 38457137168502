.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.1);
  z-index: 2;
}

.preloader-wrapper {
  &.-custom-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
    width: 120px;
    height: 120px;
    z-index: 3;
  }
}

.preloader-text {
  position: fixed;
  top: 63%;
  left: 50%;
  margin-top: -60px;
  margin-left: -60px;
  width: 150px;
  height: 120px;
  z-index: 3;
  font-size: 20px;
  color: #0060e5;
}

.preloader-text::before {
  content: "Almost there ";
}

.preloader-text::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 1s infinite;
  animation: ellipsis steps(4,end) 1s infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0;
  margin-right: 20px;
}

@keyframes ellipsis {
  to {
    width: 20px;
    margin-right: 0;
  }
}