.timeline {
  list-style-type: none;
  display: flex;
  background: #fafafa;
  justify-content: center;
  padding: 12px 0;
 }

 .author {
  font-weight: 700;
 }

.li {
  transition: all 200ms ease-in; }

.timestamp {
  margin-bottom: 20px;
  padding: 0px 12px;
  display: flex;
  font-size: 0.7rem;
  flex-direction: column;
  align-items: center;
  min-height: 55px;
  font-weight: 100; }

.status {
  padding: 0px 36px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #D6DCE0;
  position: relative;
  transition: all 200ms ease-in; }
  .status h4 {
    font-weight: 600;
    font-size: 0.7rem;
    margin-top: 8px;
    }
  .status:before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 25px;
    border: 1px solid #ddd;
    position: absolute;
    top: -9px;
    left: 42%;
    transition: all 200ms ease-in; }

.li.complete .status {
  border-top: 2px solid #012960; }
  .li.complete .status:before {
    background-color: #012960;
    border: none;
    transition: all 200ms ease-in; }
  .li.complete .status h4 {
    color: #012960;
    font-size: 0.7rem;
  }

@media (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline {
    list-style-type: none;
    display: block; }
  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit; }
  .timestamp {
    width: 100px; }
  .status:before {
    left: -8%;
    top: 30%;
    transition: all 200ms ease-in; } }
