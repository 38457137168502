/*! `Custom` Bootstrap 4 theme */

$font-size-base: 0.82rem;

$navbar-dark-color: white;
$navbar-dark-hover-color: white;

/*! `Custom` Bootstrap 4 theme */

$primary: #0060e5;
$secondary: #c9d6df;
$danger: #fe5e58;
$info: #a2d5f2;
$warning: #ffc726;
$light: #fafafa;

$enable-gradients: true;
$enable-shadows: false;

$link-hover-decoration: none;
$link-decoration: none;

$card-border-width: 0rem;

$legend-margin-bottom: null;
$legend-font-size: $font-size-base;
$legend-font-weight: bold;

@import "bootstrap/scss/bootstrap";
