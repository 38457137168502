@import './bootstrap';
@import './fontawesome';
@import './flatpickr';
@import './actiontext';
@import './pageloader';
@import './skeleton';
@import './timeline';
@import './int_tel_input.scss';

.text-gray {
  color: gray;
}

.iti {
  display: block;
  position: relative;
}

label.required:after {
  content:" *";
}

.button_to,
.button_to input {
  display: inline;
}

.form-control:focus {
  border-color: #0060e5;
  box-shadow: none;
}

.card {
  margin-bottom: 0.2rem;
}

caption {
  caption-side: top;
  color: black;
  font-weight: bold;
  text-align: center;
}

.navbar-dark, .navbar-side a.active {
  background: #125C90;
}

.navbar-side a.active {
  color: white;
}

#navbar-autocomplete {
  position: absolute;
  left: 790px;
  right: 0;
  top: 40px;
  z-index: 9999;
  width: 100%;
}

.page-header {
  padding-bottom: 12px;
  padding-top: 12px;
}

.search-click {
  transition: all 0.8s !important;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;

  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .82rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: inline-block;
  width: auto;
  vertical-align: middle;
  outline: none;
}

.search-click:focus {
  transition: width 0.8s !important;

  width: 250px;
}

/* Mimic Bootstrap 4 */
.btn-light.active {
  background: #E1E1E1;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid gray;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.separator-custom {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator-custom::before,
.separator-custom::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #f9c056;
}

.separator-custom:not(:empty)::before {
  margin-right: .25em;
}

.separator-custom:not(:empty)::after {
  margin-left: .25em;
}


tbody#highlightRows tr:hover {
  background-color: #c0d4ed !important;
  position: relative;
}

.scrollable-dropdown {
  overflow-y: auto;
  max-height: 500px;
}

.without-border-radius {
  border-radius: unset;
}

.btn-outline-dark {
  color: #212529;
  border-color: #dee2e6;
}

.td-fit {
    white-space: nowrap;
    width: 1%;
}

.form-field-icon {
  background-color: black;
  color: white
}

.card-custom {
  border: 1px solid rgb(72 72 72 / 20%);
  padding: 8px;
  font-size: 12px;
  color: #524f4f;

  &.card-nav {
    flex-direction: row;
    background-color: #1f3451;
    align-items: center;
    color: white;
    padding: 8px 20px;
    border-bottom-left-radius: 24px;
  }
}

.bordered-nav-link {
  border: 0.5px solid gray !important
}

.border-less {
  border-radius: 0 !important
}

.no-content:after {
  content: unset !important;
}

.with-hovering-effect:hover {
  box-shadow: 5px 5px 5px 5px gray;
}

.version-number {
  color: rgb(0 0 0 / 20%);
  position: absolute;
  top: 95%;
  font-weight: bold;
  font-size: 16px;
  left: 95%;
}

@media (max-width:450px)  {
  .version-number {
    display: none;
  }
}

.grayed-background {
  background-color: rgb(0 0 0 / 25%);
}

.vc-modal-header {
  background-color: #004db7;
  color: white;
  border-bottom: none;
}

.about-system-bg {
  background: #0c1c39;
  color: #61ecf0;

  > div {
    padding: 8px; 
    display: flex;
    flex-direction: column;

    > img {
      margin: 0 auto;
      width: 60%;
    }

    > div {
      padding: 22px 0;
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 0 auto;

      > p {
        margin: 0;
        font-weight: 600;
      }
    }
  }
}

.form-select-custom {
  option {
    margin: 4px 0;
  }

  option:hover {
    background-color: #e6e6e6;
  }
}

.ts-control {
  border: 0;
  padding: 4px;
}

.bordered {
  border: 1px solid lightgray;
}

.file-upload-preview-container {
  width: 175px;
  min-height: 175px;

  .bordered-div {
    position: relative;
    border: 1px solid rgb(191, 209, 229);
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    height: 100%;
    display: grid;

    span {
      position: absolute;
      right: -10px;
      top: -12px;
      font-size: 1.2rem;
      cursor: pointer;
    }

    img {
      width: 150px;
      height: 150px;
      align-self: end;
    }

    .file-info {
      align-self: end;
      font-size: 0.8rem;
    }
  }
}

.bordered-card {
  border: 2px solid rgb(72 72 72 / 20%);
  &.--yellow {
    border-left: 12px solid #d69f00;
  }

  &.--red {
    border-left: 12px solid #b92a25;
  }

  &.--blue {
    border-left: 12px solid #004db7;
  }

  &.--vc-blue {
    border-left: 12px solid #012960;
  }
}

// Accordion
.accordion-button {
  background-color: white !important;
  color: black !important;
  font-style: italic;
  .fas {
    margin-right: 6px;
  }

  &:focus {
    box-shadow: unset;
    color: black;
  }
}

// Accordiion

// Tom Select Customization
.ts-wrapper {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.ts-wrapper .option .title {
	display: block;
}

.ts-wrapper .option .help {
	font-size: 12px;
	display: block;
	color: #a0a0a0;
}

.google-login {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  color: #555;
  font-size: 1em;
  font-weight: 700;
  background: #fff;
  border: #bbb solid 1px;
  border-bottom-width: 2px;

  &:hover {
    background: #eee;
  }

  > .google-logo {
    position: absolute;
    left: 0;
    padding: 8px;
  }

  > p {
    margin: 0;
  }
}

// Xero History modal
.xero-history {
  max-height: 80vh;
  overflow: auto;

  > div > .heading {
    > h2 {
      font-size: 1.5em;
      font-weight: 800;
    }
  }

  > div > .card {
    border: 1px #a0a0a0 solid;
    padding: 8px;
    margin-bottom: 8px;

    > p {
      &:first-of-type {
        font-size: 1.2em;
      }

      margin: 0
    }
  }
}

.bg-pending {
  background-color: $warning;
}

.bg-approved {
  background-color: $success;
}

.bg-declined {
  background-color: $danger;
}

.trix-button-group.trix-button-group--file-tools {
  display:none;
}